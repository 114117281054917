
import { Component, Vue, Watch } from 'vue-property-decorator';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import TermsOfService from '@/components/TermsOfService.vue';
import CoppaPrivacyPolicy from '@/components/Coppa.vue';
import GpdrPolicy from '@/components/GpdrPolicy.vue';
import CookiePolicy from '@/components/CookiePolicy.vue';

@Component({
  name: 'FAQ',
  components: {
    PrivacyPolicy,
    TermsOfService,
    CoppaPrivacyPolicy,
    GpdrPolicy,
    CookiePolicy,
  },
  metaInfo: {
    title: 'FAQ | SkillToon - Master New Skills with Live Interactive Workshops',
    link: [{ rel: 'canonical', href: 'https://skilltoon.com/faq' }],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/login' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: 'FAQ | SkillToon - New Way of Teaching & Learning Skills',
      },
      {
        property: 'og:description',
        content:
          'Join SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!',
      },
      {
        property: 'og:image',
        content: 'https://app-dev.skilltoon.com/logo-color.svg',
      },
      {
        name: 'description',
        content: 'Dive into our immersive live workshops where every session is a stepping stone towards mastering your skills, connecting with experts, and transforming your passion into expertise. Join SkillToon to start shaping your future today!'
      },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/login' },
      {
        property: 'twitter:title',
        content: 'FAQ | SkillToon - New Way of Teaching & Learning Skills',
      },
      {
        property: 'twitter:description',
        content:
          'Join SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!',
      },
      {
        property: 'twitter:image',
        content: 'https://app-dev.skilltoon.com/logo-color.svg',
      },
    ],
  },
})
export default class FAQ extends Vue {
  tab = 'About Us';
  payments: number[] = [];

  @Watch('$route.query.category')
  onViewChanged() {
    this.tab = this.$route.query.category.toString();
  }

  checkIfCreateAccountQuestionIsTrue() {
    if (Boolean(this.$route.query.category) === true) {
      this.payments.push(4);
    }
  }

  changeTab(value: string) {
    if (this.tab !== value) {
      this.$router.push({ query: { category: value } });
      this.tab = value;
    }
  }

  mounted() {
    this.onViewChanged();
    this.checkIfCreateAccountQuestionIsTrue();
  }
}
